import { defineStore } from "pinia";
import { ref } from "vue";

export const useInfoStore = defineStore("info",	() => {
	const darkMode = ref<boolean>(true);

	function toggleDarkMode() {
		const element = document.documentElement; // .querySelector('html');
		const _b = 1;
		element.classList.toggle("my-app-dark");
		darkMode.value = element.classList.contains("my-app-dark");
	}
	const _foo = {
		bar: "This is a bar.",
		baz: {
			qux: "This is a qux"
		},
		difficult: "to read"
	}; // very long
	return { darkMode, toggleDarkMode };
}
);
