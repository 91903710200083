<script setup lang="ts">
import { PhotoService } from "@/services/photoService";
import Galleria from "primevue/galleria";
import { computed, onMounted, ref } from "vue";

const images: any = ref(null);
const activeIndex = ref(0);
const showThumbnails = ref(false);
const fullScreen = ref(false);
const isAutoPlay = ref(true);

const galleria = ref(null);

const slideButtonIcon = computed(() => {
	return isAutoPlay.value ? "pi pi-pause" : "pi pi-play";
});

const fullScreenIcon = computed(() => {
	return "pi pi-window-maximize";
});

const responsiveOptions = ref([
	{
		breakpoint: "1300px",
		numVisible: 4
	},
	{
		breakpoint: "575px",
		numVisible: 1
	}
]);

onMounted(async () => {
	PhotoService.getImages().then(
		data =>
			(images.value
				= data)
	);
	// Add external Feedback form
	// await useScript("https://mfpembedcdnweu.azureedge.net/mfpembedcontweu/Embed.js");

	// dataReady.finally(() => {
	// 	isLoadingData.value = false;
	// 	router.push({ name: "home" });
	// });
});

function toggleAutoSlide() {
	isAutoPlay.value
		= !isAutoPlay.value;
}

function onThumbnailButtonClick() {
	showThumbnails.value
		= !showThumbnails.value;
}

function toggleFullScreen() {
	if (fullScreen.value) {
		closeFullScreen();
	}
	else {
		openFullScreen();
	}
}

function onFullScreenChange() {
	fullScreen.value
		= !fullScreen.value;
}

function openFullScreen() {
	let elem = (
		galleria.value as any
	).$el;

	if (elem.requestFullscreen) {
		elem.requestFullscreen();
	}
	else if (
		elem.mozRequestFullScreen
	) {
		/* Firefox */
		elem.mozRequestFullScreen();
	}
	else if (
		elem.webkitRequestFullscreen
	) {
		/* Chrome, Safari & Opera */
		elem.webkitRequestFullscreen();
	}
	else if (
		elem.msRequestFullscreen
	) {
		/* IE/Edge */
		elem.msRequestFullscreen();
	}
}

function closeFullScreen() {
	if (document.exitFullscreen) {
		document.exitFullscreen();
	}
	else if ((document as any).mozCancelFullScreen) {
		(document as any).mozCancelFullScreen();
	}
	else if ((document as any).webkitExitFullscreen) {
		(document as any).webkitExitFullscreen();
	}
	else if ((document as any).msExitFullscreen) {
		(document as any).msExitFullscreen();
	}
}

function _bindDocumentListeners() {
	document.addEventListener("fullscreenchange", onFullScreenChange);
	document.addEventListener("mozfullscreenchange", onFullScreenChange);
	document.addEventListener("webkitfullscreenchange", onFullScreenChange);
	document.addEventListener("msfullscreenchange", onFullScreenChange);
}

function _unbindDocumentListeners() {
	document.removeEventListener("fullscreenchange", onFullScreenChange);
	document.removeEventListener("mozfullscreenchange", onFullScreenChange);
	document.removeEventListener("webkitfullscreenchange", onFullScreenChange);
	document.removeEventListener("msfullscreenchange", onFullScreenChange);
}
</script>

<template>
	<div class="card">
		<Galleria
			ref="galleria"
			v-model:active-index=" activeIndex "
			:value="images"
			:num-visible="4"
			container-style="max-width: 640px"
			:show-thumbnails=" showThumbnails "
			:show-item-navigators=" true "
			:show-item-navigators-on-hover=" true "
			:circular="true"
			:auto-play="isAutoPlay"
			:transition-interval="5000"
			:responsive-options=" responsiveOptions "
			:pt="{
				root: {
					class: [
						{
							'flex flex-col': fullScreen
						}
					]
				},
				content: {
					class: [
						'relative',
						{
							'flex-1 justify-center': fullScreen
						}
					]
				},
				thumbnails: 'absolute w-full left-0 bottom-0'
			}"
		>
			<template
				#item="slotProps"
			>
				<img
					:src="slotProps.item.itemImageSrc"
					:alt="slotProps .item .alt"
					:style="[
						{
							width: !fullScreen ? '100%' : '',
							display: !fullScreen ? 'block' : ''
						}
					]"
				>
			</template>
			<template #thumbnail="slotProps">
				<div class="grid gap-4 justify-center">
					<img
						:src="slotProps.item.thumbnailImageSrc"
						:alt="slotProps.item.alt"
						style="display: block;"
					>
				</div>
			</template>
			<template #footer>
				<div class="flex items-stretch bg-surface-950 text-black h-10">
					<button
						type="button"
						class="bg-transparent border-none rounded-none hover:bg-white/10
								text-black inline-flex justify-center items-center cursor-pointer px-3"
						@click="onThumbnailButtonClick"
					>
						<i class="pi pi-th-large" />
					</button>
					<button
						type="button"
						class="bg-transparent border-none rounded-none hover:bg-white/10
							text-white inline-flex justify-center items-center cursor-pointer px-3"
						@click="toggleAutoSlide"
					>
						<i :class="slideButtonIcon" />
					</button>
					<span
						v-if="images"
						class="flex items-center gap-4 ml-3"
					>
						<span class="text-sm">{{ activeIndex + 1 }}/{{ images.length }}</span>
						<span class="font-bold text-sm">{{ images[activeIndex].title }}</span>
						<span class="text-sm">{{ images[activeIndex].alt }}</span>
					</span>
					<button
						type="button"
						class="bg-transparent border-none rounded-none hover:bg-white/10
						text-white inline-flex justify-center items-center cursor-pointer px-3 ml-auto"
						@click="toggleFullScreen"
					>
						<i :class="fullScreenIcon" />
					</button>
				</div>
			</template>
		</Galleria>
	</div>
</template>

<style lang="scss">
//@import "@/styles/components/accomodation.scss";
</style>
