import AccomodationView from "@/views/AccomodationView.vue";
import HomeView from "@/views/HomeView.vue";
import PhotosView from "@/views/PhotosView.vue";
import {
	createRouter,
	createWebHistory
} from "vue-router";

const base: string = String(
	import.meta.env.BASE_URL
); // import.meta.env.BASE_URL as string;

const router = createRouter({
	history: createWebHistory(base),
	routes: [
		{
			path: "/",
			name: "home",
			component: HomeView
		},
		{
			path: "/location",
			name: "location",
			// route level code-splitting
			// this generates a separate chunk (About.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			component: () =>
				import(
					"../views/LocationView.vue"
				)
		},
		{
			path: "/accomodation",
			name: "accomodation",
			component: AccomodationView
		},
		{
			path: "/photos",
			name: "photos",
			component: PhotosView
		}
	]
});

export default router;
