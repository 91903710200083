import { createPinia } from "pinia";
import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";

// primevue & primeflex
import Lara from "@primevue/themes/lara"; // theme
import PrimeVue from "primevue/config";
// import "primevue/resources/primevue.min.css"; //core CSS
import "primeflex/primeflex.css";
import "primeicons/primeicons.css"; // icons

import "@/styles/styles.scss";

import Button from "primevue/button";
import CheckBox from "primevue/checkbox";
import InputText from "primevue/inputtext";

import SvgIcon from "@/components/SvgIcon.vue";

const app = createApp(App)
	.use(createPinia())
	.use(router)
	.use(PrimeVue, {
		ripple: true,
		zIndex: {
			modal: 1100, // dialog, sidebar
			overlay: 1000, // dropdown, overlaypanel
			menu: 1000, // overlay menus
			tooltip: 1100 // tooltip
		},
		theme: {
			preset: Lara,
			options: {
				// prefix: "p", //default
				darkModeSelector:
					".my-app-dark", // default is system
				cssLayer: false
			}
		}
	})
	.component("SvgIcon", SvgIcon)
	.component("Button", Button)
	.component(
		"InputText",
		InputText
	)
	.component(
		"Checkbox",
		CheckBox
	);

app.mount("#app");
