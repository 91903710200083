<script setup lang="ts">
// import LayoutComponent from "./components/LayoutComponent.vue";
// import Button from 'primevue/button';
import { useInfoStore } from "@/stores/info";
import Tab from "primevue/tab";
import TabList from "primevue/tablist";
import Tabs from "primevue/tabs";
import { computed, onMounted, ref } from "vue";
import {
	RouterLink,
	RouterView
} from "vue-router";

const _checked = ref<boolean>(true);
const info = useInfoStore();

const modeIcon = computed(() =>
	info.darkMode
		? "pi pi-sun"
		: "pi pi-moon"
);

const items = ref([
	{
		route: "/photos",
		label: "Dashboard",
		icon: "pi pi-home"
	},
	{
		route: "/transactions",
		label: "Transactions",
		icon: "pi pi-chart-line"
	},
	{
		route: "/products",
		label: "Products",
		icon: "pi pi-list"
	},
	{
		route: "/messages",
		label: "Messages",
		icon: "pi pi-inbox"
	}
]);

onMounted(() => {
	info.toggleDarkMode();
});
</script>

<template>
	<Button
		:icon="modeIcon"
		rounded
		@click="
			info.toggleDarkMode()
		"
	/>

	<div class="card">
		<Tabs
			value="/dashboard"
		>
			<TabList>
				<Tab
					v-for="tab in items"
					:key="
						tab.label
					"
					:value="
						tab.route
					"
					alt="aaa"
				>
					<router-link
						v-if="
							tab.route
						"
						v-slot="{
							href,
							navigate
						}"
						:to="
							tab.route
						"
						custom
					>
						<a
							:href="
								href
							"
							class="flex items-center gap-2 text-inherit"
							@click="
								navigate
							"
						>
							<i
								:class="
									tab.icon
								"
							/>
							<span>{{
								tab.label
							}}</span>
						</a>
					</router-link>
				</Tab>
			</TabList>
		</Tabs>
	</div>

	<!-- <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
		<div class="text-center mb-5">
			<img
				src="/images/blocks/logos/hyper.svg"
				alt="Image"
				height="50"
				class="mb-3"
			>
			<div class="text-900 text-3xl font-medium mb-3">Welcome Back</div>
			<span class="text-600 font-medium line-height-3">Don't have an account?</span>
			<a class="font-medium no-underline ml-2 text-blue-500 cursor-pointer">Create today!</a>
		</div>

		<div>
			<label
				for="email1"
				class="block text-900 font-medium mb-2"
			>Email</label>
			<InputText
				id="email1"
				type="text"
				class="w-full mb-3"
			/>

			<label
				for="password1"
				class="block text-900 font-medium mb-2"
			>Password</label>
			<InputText
				id="password1"
				type="password"
				class="w-full mb-3"
			/>

			<div class="flex align-items-center justify-content-between mb-6">
				<div class="flex align-items-center">
					<Checkbox
						id="rememberme1"
						v-model="checked"
						:binary="true"
						class="mr-2"
					/>
					<label for="rememberme1">Remember me</label>
				</div>
				<a class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">Forgot password?</a>
			</div>

			<Button
				label="Sign In"
				icon="pi pi-user"
				class="w-full"
				rounded
			/>
		</div>
	</div>	 -->
	<RouterView />
</template>
