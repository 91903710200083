export const PhotoService = {
	getData() {
		const files = [];

		for (
			let i = 1;
			i <= 1269;
			i++
		) {
			const file = {
				itemImageSrc: `https://ivmarkov.com/photos/${i}.jpg`,
				thumbnailImageSrc: `https://ivmarkov.com/photos/thumbs/${i}.jpg`,
				alt: `Photo ${i}`,
				title: i
			};
			files.push(
				file
			);
		}

		return files;
		// return [
		//     {
		//         itemImageSrc: 'https://primefaces.org/cdn/primevue/images/galleria/galleria1.jpg',
		//         thumbnailImageSrc: 'https://primefaces.org/cdn/primevue/images/galleria/galleria1s.jpg',
		//         alt: 'Description for Image 1',
		//         title: 'Title 1'
		//     }
		// ];
	},

	getImages() {
		return Promise.resolve(
			this.getData()
		);
	},

	aaa() {
		return 10;
	}
};
