<script setup lang="ts"></script>

<template>
	<div class="card">
		<div
			class="card-container"
		>
			<div
				class="block text-center card bg-green-900 text-white normaltext"
			>
				We
				recommend
				the
				following
				hotels:
			</div>
			<div
				class="block bg-yellow-500 font-bold text-center p-4 border-round mb-3"
			>
				<a
					class="hotel-link"
					href="https://www.booking.com/hotel/gr/art-boutique.en.html?aid=8001693
                    &checkin_monthday=22&checkin_month=6&checkin_year=2023&checkout_monthday=25
                    &checkout_month=6&checkout_year=2023&no_rooms=1&group_adults=2&room1=A%2CA"
					target="_blank"
				>
					Art
					Suites,
					Pefkohori
				</a>
			</div>
			<div
				class="block bg-yellow-500 font-bold text-center p-4 border-round mb-3"
			>
				<a
					class="hotel-link"
					href="https://www.booking.com/hotel/gr/palm-suites.en.html?aid=8001693
                &checkin_monthday=22&checkin_month=6&checkin_year=2023&checkout_monthday=25
                &checkout_month=6&checkout_year=2023&no_rooms=1&group_adults=2&room1=A%2CA"
					target="_blank"
				>
					Palm
					Suites,
					Chalkidiki
				</a>
			</div>
			<div
				class="block bg-yellow-500 font-bold text-center p-4 border-round mb-3"
			>
				<a
					class="hotel-link"
					href="https://www.booking.com/hotel/gr/emily-luxury-suites.en.html?aid=8001693
                &checkin_monthday=22&checkin_month=6&checkin_year=2023&checkout_monthday=25
                &checkout_month=6&checkout_year=2023&no_rooms=1&group_adults=2&room1=A%2CA"
					target="_blank"
				>
					Emily's
					Luxury
					Suites,
					Pefkohori
				</a>
			</div>
		</div>
	</div>
</template>

<style lang="scss">
@import '@/styles/components/accomodation.scss';
</style>
